<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h1>{{ $t('veranstalter_seite.benutzerprofil.label_benutzerprofil') }}</h1>
        </b-col>
        <b-col cols="auto" class="d-flex flex-row align-items-center">
          <b-button class="mr-2" :to="`/${$i18n.locale}/veranstalter/uebersicht`">
            {{ $t('veranstalter_seite.label_zurueck') }}
          </b-button>
          <b-button @click="logoutUser">
            {{ $t('veranstalter_seite.label_abmelden') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p v-html="$t('veranstalter_seite.benutzerprofil.info_benutzerprofil_1')"></p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form>
            <b-row>
              <b-col sm="6">
                <!-- Feld: Vorname -->
                <b-form-group
                  id="fieldset-contactFirstName"
                  :label="$t('veranstalter_seite.benutzerprofil.label_vorname') + ' *'"
                  label-for="input-contactFirstName"
                >
                  <b-form-input
                    id="input-contactFirstName"
                    v-model="user.contactFirstName"                  
                    aria-describedby="feedback-contactFirstName"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <!-- Feld: Nachname -->
                <b-form-group
                  id="fieldset-contactName"
                  :label="$t('veranstalter_seite.benutzerprofil.label_nachname') + ' *'"
                  label-for="input-contactName"
                >
                  <b-form-input
                    id="input-contactName"
                    v-model="user.contactName"                  
                    aria-describedby="feedback-contactName"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <!-- Feld: Adresse -->
                <b-form-group
                  id="fieldset-contactAddress"
                  :label="$t('veranstalter_seite.benutzerprofil.label_adresse') + ' *'"
                  label-for="input-contactAddress"
                >
                  <b-form-input
                    id="input-contactAddress"
                    v-model="user.contactAddress"                  
                    aria-describedby="feedback-contactAddress"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <!-- Feld: Adresszusatz -->
                <b-form-input
                  id="input-contactAddressLine2"
                  v-model="user.contactAddressLine2"
                  :placeholder="$t('veranstalter_seite.benutzerprofil.label_adresszusatz_placeholder')"
                  size="sm"
                  class="mb-3"
                  disabled
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3" sm="4">
                <!-- Feld: PLZ -->
                  <b-form-group
                    id="fieldset-contactZipCode"
                    :label="$t('veranstalter_seite.benutzerprofil.label_plz') + ' *'"
                    label-for="input-contactZipCode"
                  >
                    <b-form-input
                      id="input-contactZipCode"
                      v-model="user.contactZipCode"                  
                      aria-describedby="feedback-contactZipCode"
                      size="sm"
                      disabled
                    ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="9" sm="8">
                <!-- Feld: Ort -->
                <b-form-group
                  id="fieldset-contactCity"
                  :label="$t('veranstalter_seite.benutzerprofil.label_ort') + ' *'"
                  label-for="input-contactCity"
                >
                  <b-form-input
                    id="input-contactCity"
                    v-model="user.contactCity"                  
                    aria-describedby="feedback-contactCity"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Feld: Veranstalungsadresse (Kanton) (Wenn bei "Sportanlage / Stadion" ein vordefiniertes Stadion gewählt ist, wird dies vorausgefüllt) -->
              <b-col>
                <b-form-group
                  id="fieldset-contactCanton"
                  :label="$t('veranstalter_seite.benutzerprofil.label_kanton') + ' *'"
                  label-for="input-contactCanton"
                >
                  <b-form-input
                    id="input-contactCanton"
                    v-model="user.contactCanton"                  
                    aria-describedby="feedback-contactCanton"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <!-- Feld: Tel. tagsüber / Mobile -->
                <b-form-group
                  id="fieldset-contactMobile"
                  :label="$t('veranstalter_seite.benutzerprofil.label_tel_tagsueber_mobile') + ' *'"
                  label-for="input-contactMobile"
                  :description="$t('veranstalter_seite.benutzerprofil.label_tel_tagsueber_mobile_description')"
                >
                  <b-form-input
                    id="input-contactMobile"
                    v-model="user.contactMobile"                  
                    aria-describedby="feedback-contactMobile"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <!-- Feld: Telefon privat -->
                <b-form-group
                  id="fieldset-contactPhone"
                  :label="$t('veranstalter_seite.benutzerprofil.label_telefon_privat')"
                  label-for="input-contactPhone"
                >
                  <b-form-input
                    id="input-contactPhone"
                    v-model="user.contactPhone"                  
                    aria-describedby="feedback-contactPhone"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <!-- Feld: E-Mail -->
                <b-form-group
                  id="fieldset-email"
                  :label="$t('veranstalter_seite.benutzerprofil.label_email') + ' *'"
                  label-for="input-email"
                >
                  <b-form-input
                    id="input-email"
                    v-model="user.email"                  
                    aria-describedby="feedback-email"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Feld: Sprache -->
              <b-col>
                <b-form-group
                  id="fieldset-correspondenceLanguage"
                  :label="$t('veranstalter_seite.benutzerprofil.label_sprache') + ' *'"
                  label-for="input-correspondenceLanguage"
                >
                  <b-form-input
                    id="input-correspondenceLanguage"
                    v-model="user.correspondenceLanguage"                  
                    aria-describedby="feedback-correspondenceLanguage"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <!-- Feld: Verein / Organisation -->
                <b-form-group
                  id="fieldset-contactOrganisation"
                  :label="$t('veranstalter_seite.benutzerprofil.label_verein_organisation') + ' *'"
                  label-for="input-contactOrganisation"
                >
                  <b-form-input
                    id="input-contactOrganisation"
                    v-model="user.contactOrganisation"                  
                    aria-describedby="feedback-contactOrganisation"
                    size="sm"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import WettkampfListe from "./WettkampfListe";

export default {
  data() {
    return {
      user: false
    };
  },
  created() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.user = this.$store.getters["veranstalter/getUser"];
      if (!this.user) {
        this.logoutUser();
      }

      // Wandle LIC ID in lesbaren Text um
      this.user.contactCanton = this.$t(this.user.contactCanton);
      this.user.correspondenceLanguage = this.$t(this.user.correspondenceLanguage);
    },
    logoutUser() {
      this.$store
          .dispatch("veranstalter/logout")
          .then(() => {
            console.log("Benutzer abgemeldet");
          })
          .catch((err) => {
            console.log(err);
          })
          .then(() => {
            this.$router.push(`/${this.$i18n.locale}/veranstalter/event-erstellen`);
          });
    },
  },
};
</script>